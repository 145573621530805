<template>
  <div>
    <div class="is-flex is-flex-direction-column is-align-items-center">
      <div class="is-flex">
        <b-tooltip
            multilined
            position="is-right"
            :type="'is-dark'"
            :active="active"
            always>
          <template v-slot:content>
            <div class="is-flex is-flex-direction-column">
              <div v-if="reason">{{ reason }}</div>
              <div
                  class="is-flex is-justify-content-space-around">
                <!--<b-button size="is-small" type="is-light" rounded @click="paramControl" v-if="props.bankAccount && props.bankAccount.method === 'PARAM'" :loading="controlLoading">
            Kontrol</b-button>
            <b-button size="is-small" type="is-light" rounded @click="paybullControl" v-if="props.bankAccount && props.bankAccount.method === 'PAYBULL'" :loading="controlLoading">Kontrol</b-button>
            <b-button size="is-small" type="is-light" rounded @click="universalControl" v-if="props.bankAccount && props.bankAccount.method === 'UNIVERSAL'"
                      :loading="controlLoading">Kontrol</b-button>-->
                <b-button size="is-small" type="is-light" rounded @click="newPayTrControl" v-if="props.bankAccount && props.bankAccount.method === 'NEWPAYTR'" :loading="controlLoading">Kontrol</b-button>
                <b-button size="is-small" type="is-light" rounded @click="paypoleControl" v-if="props.bankAccount && props.bankAccount.method === 'PAYPOLE'"
                          :loading="controlLoading">
                  Kontrol</b-button>
                <b-button
                    type="is-light"
                    size="is-small"
                    rounded
                    @click="copy(props.id)"
                >{{ $t('def.copy') }}
                </b-button
                >
              </div>
            </div>
          </template>
          <b-button
              :rounded="
							typeof rounded === 'undefined' || rounded === true
						"
              size="is-small"
              :label="text"
              :type="'is-' + color"
              @click="active = !active"/>
        </b-tooltip>
      </div>
      <div class="is-flex" v-if="props.logs && props.logs.length > 0">
        <span>{{ props.logs[0].creator.username }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {TransactionService as Service} from "@/services"

export default {
  name: "DepositStatus",
  props: ["props", "rounded"],
  data() {
    return {
      active: false,
      controlLoading: false,
      controlMessage: null,
    }
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(`
Status: ${this.text}\t
Reason: ${this.reason}\t
Transaction ID: ${this.props.uuid}\t
Process ID: ${this.props.processId}\t
Amount: ${this.props.total}\t
Username: ${this.props.username}\t
Name: ${this.props.name}\t
User ID: ${this.props.userId}\t
Created Date: ${this.dateFormat(
          this.props.createdAt,
          this.dateFormats.normalDateTime
      )}`)

      this.$buefy.toast.open({
        message: this.$t('def.copied'),
        type: "is-success",
      })
      this.active = false
    },
    paramControl() {
      this.controlLoading = true
      Service.control({transactionId: this.props.uuid})
          .then((res) => {
            this.$buefy.toast.open({
              message: res.data.message,
              type:
                  res.data.status === true
                      ? "is-success"
                      : "is-danger",
            })
          })
          .finally(() => (this.controlLoading = false))
    },
    paybullControl() {
      this.controlLoading = true
      Service.paybullControl({transactionId: this.props.uuid})
          .then((res) => {
            this.$buefy.toast.open({
              message: res.data.message,
              type:
                  res.data.status === true
                      ? "is-success"
                      : "is-danger",
            })
          })
          .finally(() => (this.controlLoading = false))
    },
    universalControl() {
      this.controlLoading = true
      Service.universalControl({transactionId: this.props.uuid})
          .then((res) => {
            this.$buefy.toast.open({
              message: res.data.message,
              type:
                  res.data.status === true
                      ? "is-success"
                      : "is-danger",
            })
          })
          .finally(() => (this.controlLoading = false))
    },
    newPayTrControl() {
      this.controlLoading = true
      Service.newPayTrControl({transactionId: this.props.uuid})
          .then((res) => {
            this.$buefy.toast.open({
              message: res.data.message,
              type:
                  res.data.status === true
                      ? "is-success"
                      : "is-danger",
            })
          })
          .finally(() => (this.controlLoading = false))
    },
    paypoleControl() {
      this.controlLoading = true
      Service.paypoleControl({transactionId: this.props.uuid})
          .then((res) => {
            this.$buefy.toast.open({
              message: res.data.message,
              type:
                  res.data.status === true
                      ? "is-success"
                      : "is-danger",
            })
          })
          .finally(() => (this.controlLoading = false))
    },
  },
  computed: {
    text() {
      return this.$t('statusTypes.' + this.props.status.toLowerCase())
    },
    color() {
      return this.transactionStatusTypes[this.props.status].color
    },
    reason() {
      return this.props.statusReason
    },
  },
}
</script>
